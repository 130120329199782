import React from 'react';
import ReactDOM from 'react-dom';
import Communications from './Communications';
import * as serviceWorker from './serviceWorker';

declare global {
  interface Window {
    renderCommunications: Function;
    unmountCreatereactapp: Function;
  }
}

window.renderCommunications = (containerId: string, session: any) => {
  ReactDOM.render(
    <Communications session={session} />,
    document.getElementById(containerId),
  );
  serviceWorker.unregister();
};

// window.unmountCreatereactapp = (containerId: string) => {
//   containerId ? ReactDOM.unmountComponentAtNode(document.getElementById(containerId)) : '';
// };

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
