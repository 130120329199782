import React from 'react';
import AWSAppSyncClient from 'aws-appsync';
import './Communications.css';

interface CommunicationsProps {
  session: {
    accountNumber: string;
    awsAppSyncClient: AWSAppSyncClient<any>;
  };
}

const Communications = ({ session }: CommunicationsProps) => {
  return (
    <div className="Communications">
      <header className="Communications-header">
        <p>Communications</p>
        <code>${session.accountNumber}</code>
      </header>
    </div>
  );
};

export default Communications;
